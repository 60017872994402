import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useLocalStorage } from '@shared/utils';
import { Languages } from '@app/i18n';
import { LanguageButton } from './LanguageButton';

import styles from './styles.module.scss';
import { useDidMount } from '@/shared/hooks/useDidMount';

export const Language: React.FC = () => {
    const [, i18n] = useTranslation();
    const [lang, setLang] = useLocalStorage('lang', '');

    useDidMount(() => {
        if (!lang) setLang(i18n.language);
    });

    const changeLanguage = useCallback(
        (lng: string) => {
            i18n.changeLanguage(lng);
            setLang(lng);
        },
        [i18n, setLang]
    );

    return (
        <div className={styles.dropdown}>
            <a className={styles.selected}>{i18n.language.toUpperCase()}</a>
            <div className={styles['dropdown-items']}>
                {Object.keys(Languages).map((lng) => (
                    <LanguageButton language={lng} current={i18n.language} change={changeLanguage} key={lng} />
                ))}
            </div>
        </div>
    );
};
